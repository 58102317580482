/* eslint-disable no-nested-ternary */
import React from 'react'
import { Layout } from '../../components/layout'
import Meta from '../../components/layout/meta'
import { RelativeContainer, ComponentContainer, ColoredBackgroundWithProps } from '../../components/shared/landingPages/layoutComponents'
import Header from '../../components/shared/landingPages/header'
import TopHighlights from '../../components/shared/landingPages/topHighlights'
import HighlightedFunctionality from '../../components/shared/landingPages/highlightedFunctionality'
import FeatureLinks from '../../components/shared/landingPages/featureLinks'
import Benefits from '../../components/shared/landingPages/benefits'
import Book from '../../images/invoicelp/book.js'
import PlusPage from '../../images/invoicelp/plusPage.js'
import Earth from '../../images/invoicelp/earth.js'
import Bonnen from '../../images/invoicelp/bonnen.js'
import Bag from '../../images/invoicelp/bag.js'
import Credit from '../../images/icons/credit.js'
import Invoicing from '../../images/lottieAnimations/InvoicingAnimation.js'
import professional from '../../images/invoicelp/professional.png'
import benefit from '../../images/invoicelp/benefits.png'
import { Accordeon } from '../../components/UI'
import { Title } from '../../components/shared/landingPages/title'

const headerData = {
    title: `Eenvoudig facturen maken en versturen`,
    text: `Creëer eenvoudig professionele inkoop- en verkoopfacturen en verstuur ze direct aan je klant. De facturen voldoen aan alle regelgeving en worden gelijk verwerkt in je boekhouding. Zie welke facturen nog openstaan en stuur automatische betalingsherinneringen zodat je sneller betaald wordt.`,
    animation: <Invoicing />,
    textCTA: `Gebruik Tellow gratis`,
}

const topHighlightData = {
    title: `Factureren met Tellow`,
    points: [
        {
            Icon: Book,
            title: `Handig adresboek`,
            body: `
            Sla de gegevens van je contacten op in het adresboek en valideer KvK-nummers in de KvK-database.
        `,
        },
        {
            Icon: Bonnen,
            title: `On the go`,
            body: `
            Met de handige app van Tellow kan je facturen maken en versturen waar en wanneer je maar wilt.
        `,
        },
        {
            Icon: Earth,
            title: `Engelstalige facturen`,
            body: `
            Stel gemakkelijk Engelse facturen op die je kunt versturen naar niet-Nederlandstalige klanten.
        `,
        },
        {
            Icon: PlusPage,
            title: `Documenten toevoegen`,
            body: `
            Voeg eenvoudig de algemene voorwaarden of andere specificaties toe als bijlage. Zo heeft je klant alle benodigde informatie bij de hand.
        `,
        },
    ],
}

const highlightedFunctionalityData = {
    Icon: Bag,
    title: `Professionele uitstraling`,
    subtitle: `Met Tellow maak je eenvoudig professionele facturen. Gebruik een van onze standaard templates of ontwerp facturen die passen bij jouw huisstijl. Upload het logo van je bedrijf en kies de kleuren je wilt gebruiken.`,
    firstStepImage: professional,
    textCTA: `Probeer het zelf`,
}

const benefitsData = {
    points: [
        {
            title: 'Altijd compleet',
            body: 'De facturen die je verstuurt via Tellow zijn volledig en voldoen aan alle eisen. Vul zelf je gegevens in wij stellen een factuur voor je op. Factuurnummers, btw-bedragen en totaalbedragen: alles wordt automatisch berekend en ingevuld.',
            links: 'Maak je eerste factuur',
            url: `https://app.tellow.nl/registreer#/`,
        },
        {
            title: 'Op tijd betaald worden',
            body: 'Tellow geeft je een handig overzicht van betaalde en niet-betaalde facturen. Ook sturen we automatisch betalingsherinneringen. Zo word je sneller betaald en loop je nooit geld mis.',
            links: 'Bekijk het overzicht ',
            url: `https://app.tellow.nl/registreer#/`,
        },
        {
            title: 'Simpel en snel',
            body: 'Gebruik Tellow via je desktop of in onze handige app en maak facturen in een handomdraai. Je factuur wordt automatisch opgesteld en is binnen een paar klikken verstuurd aan de klant.',
            links: 'Maak een account',
            url: `https://app.tellow.nl/registreer#/`,
        },
    ],
    benefitImage: benefit,
    altImage: `Benefit Image`,
}

const featureData = [
    {
        Icon: Credit,
        title: `Offertes`,
        text: `Stuur professionele offertes en zet deze direct om in facturen. Verhoog de acceptatieratio van je offertes door je eigen huisstijl en logo te gebruiken.`,
        link: `Lees meer over offertes maken`,
        path: `offertes`,
    },
    {
        Icon: Credit,
        title: `Btw-aangifte doen`,
        text: `Door de koppeling met de belastingdienst doe jij nu je aangifte in enkele klikken. Super makkelijk en snel.`,
        link: `Lees meer over aangiftes doen met Tellow`,
        path: `btw-aangifte`,
    },
]

const FAQ = [
    {
        question: 'Hoe maak ik een factuur?',
        answer: `
            Bepaal in welk software je een factuur wilt maken. Word, Excel of een geavanceerd facturatieprogramma zoals Tellow?

            <ul>
                <li>Kies eerst je sjabloon uit en voeg je logo toe.</li>
                <li>Voeg alle verplichte informatie toe.</li>
                <li>Voeg overige relevante informatie toe.</li>
                <li>Geef aan welk btw-tarief je in rekening brengt en wat het btw-bedrag is.</li>
                <li>Verstuur de factuur direct na afronding van de opdracht.</li>
            </ul>

            De gegevens die je verplicht moet noteren kun je vinden bij de vraag ‘Welke gegevens moet ik verplicht noteren op een factuur?’.<br/>
            Bij Tellow maak je gratis facturen in een handomdraai. Maak gebruik van onze professionele templates met je eigen logo.
        `,
    },
    {
        question: 'Welke gegevens moet ik verplicht noteren op een factuur?',
        answer: `
            De volgende punten moet je verplicht vermelden op een factuur:

            <ol>
                <li>Je btw-identificatienummer en het btw-bedrag.</li>
                <li>Je KVK-nummer.</li>
                <li>De factuurdatum en het factuurnummer (de factuurnummering van je facturen moet doorlopend zijn).</li>
                <li>De datum van levering.</li>
                <li>Naam & adres van jezelf en je klant.</li>
                <li>De goederen of diensten die je levert.</li>
                <li>Hoeveel geleverde goederen of gewerkte uren je in rekening brengt.</li>
            </ol>

            Klik <a href="https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/belastingdienst/zakelijk/btw/administratie_bijhouden/facturen_maken/factuureisen/factuureisen" target="_blank">hier</a> voor meer informatie.
        `,
    },
    {
        question: 'Kan ik zelf een factuur maken?',
        answer: `
            Facturen kan en mag je zelf maken, maar let wel goed op alle regels die er aan verbonden zijn. Het is belangrijk dat je alle gegevens correct noteert en dat je de facturen bewaart, er is namelijk een wettelijke bewaarplicht van 7 tot 10 jaar.
        `,
    },
    {
        question: 'Hoe factuur maken zonder btw?',
        answer: `
            Een factuur maken zonder btw is niet heel anders dan het maken van een factuur met btw. Je voegt simpelweg de bedragen toe excl. btw en je hoeft ook geen btw-identificatienummer toe te voegen. Het is belangrijk dat je verder wel alle verplichte gegevens invult, ook de verschuldigde omzetbelasting en het omzetbelastingnummer.
            <br/><br/>
            Als je facturen maakt met Tellow kun je eenvoudig ‘geen btw’ of ‘vrijgesteld van btw’ in ons systeem aangeven. De factuur wordt dan automatisch zonder btw gemaakt.
        `,
    },
    {
        question: 'Hoe maak je een factuur in Word?',
        answer: `
            Stappenplan facturen maken in Word:

            <ul>
                <li>Kies een sjabloon uit.</li>
                <li>Voeg alle verplichte informatie toe, zoals naam en adres.</li>
                <li>Voeg relevante informatie over de verzonden of gekochte artikelen toe.</li>
                <li>Zet het Word bestand om in een pdf-bestand.</li>
            </ul>

            Word bestand exporteren naar pdf:<br/>
            Bestand > exporteren > selecteer ‘pdf of xps maken’.
        `,
    },
    {
        question: 'Hoe maak ik een factuur in Excel?',
        answer: `
            Stappenplan facturen maken in Excel:

            <ul>
                <li>Download een factuursjabloon voor Excel of maak zelf een sjabloon.</li>
                <li>Voer de klantgegevens in.</li>
                <li>Selecteer je verkochte producten/ diensten en aantallen.</li>
                <li>Kies je factuur kleur en download je factuur in pdf.</li>
            </ul>

            Excel naar pdf:<br/>
            Bestand > download > selecteer pdf (.pdf) > selecteer ‘exporteer’.
        `,
    },
    {
        question: 'Hoe gratis factuur maken?',
        answer: `
            Gratis facturen maken kan bijvoorbeeld via Microsoft Word, Excel en bij Tellow.
            <br/><br/>
            Als je facturen maakt met Tellow kies je zelf een van onze professionele templates met je eigen logo. Verder vul je de gegevens in en wij zullen het totaalbedrag en de btw automatisch berekenen. Ook heeft Tellow een handig overzicht van welke facturen zijn betaald en welke nog niet.
        `,
    },
]

const Facturen = ({ showAnimation }) => (
    <Layout>
        <Meta
            keywords={['zzp', 'facturen', 'tellow']}
            path="/functies/facturen"
            title="Facturen | Tellow.nl"
            description="Snel online boekhouden met hét online boekhoudprogramma voor zzp'ers ✅ 100.000+ ondernemers gingen je voor ✅ Registreer gratis!"
        />
        <Header data={headerData} showAnimation displayImage={false} />

        <ColoredBackgroundWithProps purple>
            <RelativeContainer>
                <ComponentContainer>
                    <HighlightedFunctionality data={highlightedFunctionalityData} purpleBackground bottomImage />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        <RelativeContainer>
            <ComponentContainer>
                <Benefits data={benefitsData} />
            </ComponentContainer>
        </RelativeContainer>

        <ColoredBackgroundWithProps gray100>
            <RelativeContainer>
                <ComponentContainer>
                    <TopHighlights data={topHighlightData} />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        <RelativeContainer>
            <ComponentContainer>
                <FeatureLinks data={featureData} />
            </ComponentContainer>
        </RelativeContainer>

        <RelativeContainer>
            <ComponentContainer direction="column" padding={1.5}>
                <Title>Veelgestelde vragen</Title>
                {FAQ.map(({ question, answer }) => (
                    <Accordeon key={question} question={question}>
                        <div dangerouslySetInnerHTML={{ __html: answer }} />
                    </Accordeon>
                ))}
            </ComponentContainer>
        </RelativeContainer>
    </Layout>
)

export default Facturen
