import React from 'react'
import Lottie from 'lottie-react'
import LottieInvoicingAnimation from './invoicing.json'
import { AnimationContainer } from './AnimationContainer.styles.js'

const InvoicingAnimation = () => (
    <AnimationContainer>
        <Lottie animationData={LottieInvoicingAnimation} />
    </AnimationContainer>
)

export default InvoicingAnimation
